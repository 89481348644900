html, body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

.selection-grid .MuiPaper-root {
  padding: 8px !important;
}

header > div {
  padding-right: 0 !important;
}

header {
  padding: 0 !important;
}

#root .MuiGrid2-root:first-of-type > .MuiGrid2-root{
  padding: 0 !important;
}
